import React from "react"
import { Card, CardTitle, CardBody, Form, FormGroup, CardText } from "reactstrap"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import KitchenCalculator from '../pages/kitchenCalculator'

import FalseCeilingCalculator from '../pages/falseceiling'
import tagPost from '../templates/tag-posts'
import tagPage from '../templates/tags-page'

import ReactDOM from "react-dom"
//This is used for horizontal line
const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 3
      }}
  />
);

const ColoredThinLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1
      }}
  />
);
const Sidebar = () => (
  <div>
    <Card>
      <CardBody>
        <CardTitle className="text-center text-uppercase mb-3">
          <b>Recommended</b>
        </CardTitle>
        <Form className="text-center">
          {/* <FormGroup>
            <Input
              type="email"
              name="email"
              placeholder="Your email address.."
            />
          </FormGroup>
          <button className="btn btn-outline-success text=uppercase">
            Subscribe
          </button> */}

          <FormGroup>
          <Link to="/how-to-start-with-interior-designing-work" activeStyle={{ color: "red" }}>घर की इंटीरियर डिज़ाइन कैसे करें</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="/how-to-make-a-small-room-look-bigger" activeStyle={{ color: "red" }}>छोटे घर को बड़ा दिखाने का तरीका</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="/must-read-before-modular-kitchen-design" activeStyle={{ color: "red" }}>किचन डिज़ाइन करने से पहले किन किन बातों को ध्यान में रखने की जरुरत है</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="/10x12-10x12-bedroom-design"  activeStyle={{ color: "red" }}>10x12 बैडरूम डिज़ाइन कैसे करें </Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="/kitchen-color-combination" activeStyle={{ color: "red" }}>मॉडुलर किचन - कलर कैसे चुने</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="/or-pop-gypsum-vs-pop" activeStyle={{ color: "red" }}>जिप्सम या POP ? कौन सा अच्छा ऑप्शन है</Link>
          </FormGroup>
          <ColoredThinLine/>
          
          <FormGroup>
          <Link to="/small-kitchen-design" activeStyle={{ color: "red" }}>छोटा किचन की डिज़ाइन कैसे करें </Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="/how-to-select-wood-for-interior-design-work" activeStyle={{ color: "red" }}>घर के इंटीरियर के लिए लकड़ी का चुनाव कैसे करें </Link>
          </FormGroup>

          <ColoredThinLine/>
          <FormGroup>
          <Link to="/mdf-where-to-use-mdf-in-interior-designing" activeStyle={{ color: "red" }}>इंटीरियर डिजाइनिंग में MDF का इस्तेमाल कहाँ करना चाहिए</Link>
          </FormGroup>

          <ColoredThinLine/>
          <FormGroup>
          <Link to="/vs-plywood-vs-blockboard" activeStyle={{ color: "red" }}>Plywood vs BlockBoard - किसका इस्तेमाल हमे कब करना चाहिए</Link>
          </FormGroup>

          

          
          
        </Form>
        
        <ColoredLine color="red" />
        <Form className="text-center" color='gray'>

        <CardTitle className="text-center text-uppercase mb-3">
          <b>Video reference links</b>
        </CardTitle>
          <FormGroup>
          <Link to="https://youtu.be/-JY9dfVo5dQ" activeStyle={{ color: "red" }}>Nano White/G5 Stone -  क्या बेस्ट ऑप्शन है किचन काउंटर टॉप के लिए  </Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/D_nwJL-w82w" activeStyle={{ color: "red" }}>Toughened/Backpainted गिलास इन किचन  </Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/EOxjSlpGXc8" activeStyle={{ color: "red" }}>घर के Interior के लिए लकड़ी का चुनाव कैसे करे</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/ne_ldHj1BNU" activeStyle={{ color: "red" }}>False ceiling कराने से पहले ये वीडियो जरूर देखे</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/JEm31IXPk_Y" activeStyle={{ color: "red" }}>घर के लिए Interior Design की प्लानिंग कैसे करे</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/ffCkeHqvrSQ" activeStyle={{ color: "red" }}>PVC Laminates क्या बेस्ट ऑप्शन है ?</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/J67m8QGWYRA" activeStyle={{ color: "red" }}>Gyspum vs POP False ceiling </Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/Y5OBpz1UE2Q" activeStyle={{ color: "red" }}>Modular Kitchen - कलर कैसे चुनें</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/EnKPQvgVs7k" activeStyle={{ color: "red" }}>Small Bedroom Design कैसे करें</Link>
          </FormGroup>
          
        </Form>
        {/* <Link to="/tags" activeStyle={{ color: "red" }}>Tags </Link> */}
        <tagPost/>
        <tagPage/>
        <ColoredLine color="red" />

        <CardTitle className="text-center text-uppercase mb-3">
          <b>False Ceiling budget Calculator</b>
        </CardTitle>

        <Form className="text-center">
          <ColoredThinLine/>
          <FormGroup>
          <Link to="/falseceilingcostcalculator" activeStyle={{ color: "red" }}>Quick False Ceiling Calculator</Link>
          </FormGroup>
          <ColoredThinLine/>

          <FormGroup>
          <Link to="/falseceilingcalculator" activeStyle={{ color: "red" }}>Advance False Ceiling Calculator</Link>
          </FormGroup>
          <ColoredLine color="red" />
        </Form>
        
      </CardBody>
    </Card>
  </div>
)

const sidebarQuery = graphql`
  query sidebarQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default Sidebar

import React, { useState } from "react"
import Select from "react-select"
import ReactTooltip from 'react-tooltip';
// import CabinetMaterial from './cabinetMaterialvalue'
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap"

class KitchenCalculator extends React.Component {
  constructor() {
    super()
    this.state = {
      measurement: "",
      countertopmeasurement: "",
      num2: "",
      total: "",
      resultShutter: 0,
      test: "",
      foodState: 0,
      cabinet: "",
      shutter: "",
      basket: "",
      countertop: ""
    }
    // this.handleChange = this.handleChange.bind(this);
    // this.handleCabinetMaterial=this.handleCabinetMaterial.bind(this);
    
  }

  handlemeasurement = event => {
    this.setState({
      measurement: event.target.value,
    })
  }

  handlecountertopmeasurement = event => {
    this.setState({
      countertopmeasurement: event.target.value,
    })
  }

  handleCabinetMaterial = event => {
    this.setState({
      cabinet: event.target.value
    })
  }

  handleShutterMaterial = event => {
    this.setState({
      shutter: event.target.value
    })
  }

  handleBasketMaterial = event => {
    this.setState({
      basket: event.target.value
    })
  }


  handleCountertopMaterial = event => {
    this.setState({
      countertop: event.target.value
    })
  }

  exe = event => {
    this.setState({
      total:
        (parseInt(this.state.measurement) * 700) +
        parseInt(this.state.countertopmeasurement) + 
        parseInt(this.state.cabinet) + 
        parseInt(this.state.shutter) + 
        parseInt(this.state.basket) + 
        parseInt(this.state.countertop)
    })
    event.preventDefault()
  }

  render() {
    return (
      <div>
        <Form className="text-center" onSubmit={this.exe}>
          <FormGroup row>
            <Label for="id_countertop_material" sm={2}>
              Kitchen Measurements
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                value={this.state.measurement}
                onChange={this.handlemeasurement}
                placeholder="Woodwork area in SFT"
              />
            </Col>
          </FormGroup>


          <FormGroup row>
            <Label for="id_cabinet_material" sm={2}>
              Cabinet Material
            </Label>
            <Col sm={10}>
              <select className="form-control mb-3" value={this.state.cabinetmaterialvalue} onChange={this.handleCabinetMaterial}>
              <option selected hidden disabled>Select Cabinet Material</option>
              <option value="50">Plywood710</option>
              <option value="60">Plywood302</option>
            </select>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="id_shutter_material" sm={2}>
              Shutter Material
            </Label>
            <Col sm={10}>
            <select className="form-control mb-3" value={this.state.shuttermaterialvalue} onChange={this.handleShutterMaterial}>
            <option selected hidden disabled>Select Shutter Material</option>
              <option value="50">Plywood710</option>
              <option value="60">Plywood302</option>
              <option value="40">MDF</option>
              </select>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="id_shutter_material" sm={2}>
              Basket
            </Label>
            <Col sm={10}>
              <select className="form-control mb-3" value={this.state.basketmaterialvalue} onChange={this.handleBasketMaterial}>
              <option selected hidden disabled>Select Basket</option>
              <option value="10000">Tandom</option>
              <option value="12000">SS</option>
              
              </select>
            </Col>
          </FormGroup>
          
          <FormGroup row>
            <Label for="id_countertop_material" sm={2}>
              Counter top
            </Label>
            <Col sm={10}>
            <select className="form-control mb-3" value={this.state.countertopmaterialvalue} onChange={this.handleCountertopMaterial}>
            <option selected hidden disabled>Select Counter Top</option>
              <option value="50">Simple</option>
              <option value="60">Luxury</option>
              </select>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="id_quantity" sm={2}>
              Countertop Measurements
            </Label>
            <Col sm={10}>
              <Input
                value={this.state.countertopmeasurement}
                onChange={this.handlecountertopmeasurement}
                placeholder="Workwork area in SFT"
                placeholder="Area in sft"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={10}>
              <label class="form-check-label" for="inlineCheckbox1">
                Select the below item(optional)
              </label>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="option1"
                ></input>
                <label class="form-check-label" for="inlineCheckbox1">
                  Chimney
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                  value="option2"
                ></input>
                <label class="form-check-label" for="inlineCheckbox2">
                  Hob
                </label>
              </div>
            </Col>
          </FormGroup>
          <FormGroup check row>
            <Col sm={{ size: 10, offset: 2 }}>
              <button className="btn btn-outline-success"> Calculate</button>
            
            </Col>
          </FormGroup>
        </Form>
        {this.state.total}
      </div>
    )
  }
}


export default KitchenCalculator
